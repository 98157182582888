@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');


:root {
    --colorPrimary: #FF007B;
    --colorSecondary: #7E6ABE;
    --colorSecondary-75: hsla(254, 75%, 80%, 0.75);
    --colorSecondary-50: rgba(126, 106, 190, 0.50);
    --colorSecondary-25: rgba(126, 106, 190, 0.25);

    --colorSecondaryShade: #2A2440;
    --colorSecondaryBright-sm: #9e8fce;
    --colorSecondaryBright-md: #bfb5df;
    

    --colorPrimaryDark-sm: #bf005c;
    --colorPrimaryDark-md: #7f003d;
    --colorPrimaryDark-lg: #40001f;

    --colorPrimaryShade-sm: #DC0060;
    --colorPrimaryShade-md: #b8004685;
    --colorPrimaryShade-md-75: hsla(337, 100%, 36%, 0.75);
    --colorPrimaryShade-lg: #94002f;
    --colorPrimaryShade-lg-75: hsla(341, 100%, 29%, 0.75);
    --colorPrimaryShade-xl: #740019;

    --colorBlack: #221f20;
    --colorBlackBright-sm: #595758;
    --colorBlackBright-md: #918f90;
    --colorBlackBright-lg: #c8c7c7;
    --colorBlack-75: rgba(34, 31, 32, 0.75);
    --colorBlack-50: rgba(34, 31, 32, 0.50);
    --colorBlack-35: rgba(34, 31, 32, 0.35);
    --colorBlack-15: rgba(34, 31, 32, 0.15);
    --colorBlack-05: rgba(34, 31, 32, 0.05);
}

#hightlightTextHero {
    text-shadow: -1px 0px 10px #FF00BD;
    color: #7E6ABE;
    }
#demotext {
    text-shadow: -1px 0px 15px #7E6ABE;
    color: #7E6ABE;    
}


.bg-primary {
    @apply
    bg-[var(--colorPrimary)]
}

.bg-secondary {
    @apply
    bg-[var(--colorSecondary)]
}

.bg-gradient-x {
    @apply
    bg-gradient-to-r 
    from-[var(--colorPrimary)]
    to-[var(--colorSecondary)]
}

.bg-gradient-diagonal {
    @apply
    bg-gradient-to-br 
    from-[var(--colorPrimaryShade-sm)] from-30%
    to-[var(--colorSecondary)] to-70%
}
.bg-gradient-b {
    @apply
    bg-gradient-to-b
    from-[var(--colorSecondary)] 
    to-[var(--colorPrimaryShade-sm)] 
}


.heroHolder {
    @apply
    grid
    bg-gradient-diagonal
    md:h-screen
    xl:h-[55rem]
}

.button-type-1 {
    @apply
    uppercase font-bold text-lg
    py-4 px-10
    bg-[var(--colorPrimary)]

    text-white

    transition-all duration-75
    hover:bg-[var(--colorPrimaryShade-sm)]
    
}

.button-type-2 {
    @apply
    font-ProWebS uppercase font-bold text-lg
    bg-[var(--colorPrimary)]

    rounded-none

    transition-all duration-75
    hover:bg-[var(--colorPrimaryShade-sm)]
}

.button-buy {
    @apply
    uppercase font-medium tracking-widest
    py-2 px-5
    bg-[var(--colorPrimary)]

    text-white

    transition-all duration-150
    hover:bg-[var(--colorSecondary)]
    
}

.dropdown-button {
    @apply
    font-ProWebS font-medium text-xl
    /* text-[var(--colorPrimary)] */
    text-white
    bg-[var(--colorSecondary)]
    px-3 py-2 my-1

    rounded-none
    shadow-lg
}

#languageFrShadow  {
    -webkit-box-shadow: inset 0px -8px 15px -2px var(--colorPrimary); 
    box-shadow: inset 0px -8px 15px -2px var(--colorPrimary);
    transition: all;   
}
#languageFrShadow:hover{
    -webkit-box-shadow: inset 0px -8px 15px 9px var(--colorPrimary); 
    box-shadow: inset 0px -8px 15px 9px var(--colorPrimary);
    transition-duration: 100ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms; 
}

#languageEnShadow  {
    -webkit-box-shadow: inset 0px -8px 15px -2px var(--colorSecondary); 
    box-shadow: inset 0px -8px 15px -2px var(--colorSecondary);
    transition: all;   
}
#languageEnShadow:hover{
    -webkit-box-shadow: inset 0px -8px 15px 9px var(--colorSecondary); 
    box-shadow: inset 0px -8px 15px 9px var(--colorSecondary);
    transition-duration: 100ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms; 
}
